import { StrainType } from "./strainTypes";

export interface Strain {
  id?: string;
  name: string;
  is_active?: boolean;
  strain_type_id: number | null;
  deleted_by?: string | null;
  account_id?: number;
  rank: number | null;
  avatar_url?: string | null;
  avatar_file_name?: string | null;
  avatar_file_size?: string | null;
  avatar_content_type?: string | null;
  avatar_original_url?: string | null;
  avatar_thumb_url?: string | null;
  avatar_variants?: string | null;
  avatar_updated_at?: string;
  avatar?: StrainAvatar;
  deleted_at?: string | null;
  created_at?: string;
  updated_at?: string | null;
  notes?: StrainNote[];
  strain_type?: StrainType | null;
}
export const strainDefault: Strain = {
  name: "",
  strain_type_id: null,
  notes: [],
  rank: null
};

interface StrainAvatar {
  avatar_file_name?: string | null;
  avatar_file_size?: string | null;
  avatar_content_type?: string | null;
  avatar_original_url?: string | null;
  avatar_thumb_url?: string | null;
  avatar_variants?: string | null;
  avatar_updated_at?: string;
}

export interface StrainNote {
  id?: number;
  name: string;
  note: string;
  shows_note_at_point_of_sale: 0 | 1;
  start_date?: string;
  end_date?: string;
  position?: number;
}

export const noteDefault: StrainNote = {
  name: "",
  note: "",
  shows_note_at_point_of_sale: 0,
  start_date: ""
};
