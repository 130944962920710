import { StrainMetrcDetails } from "@/interfaces/strainMetrc";
import { StrainType } from "@/interfaces/strainTypes";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./StrainMetrcDetails.template.vue";

@Component({
  mixins: [Template]
})
export default class StrainMetrcsDetailsComponent extends Vue {
  @Prop({ required: true })
  public value!: {
    valid: boolean;
    metrcHasChanged: boolean;
    model: StrainMetrcDetails;
  };
  @Prop()
  public loading!: boolean;
  @Prop({ default: false })
  public modifyPermission!: boolean;
  public hasChanged = false;

  public async onChange(silent = false) {
    if (!silent) {
      this.hasChanged = true;
    }

    this.$emit("input", {
      valid: await this.$validator.validateAll(),
      model: this.model,
      metrcHasChanged: this.hasChanged
    });
  }

  public get model() {
    return cloneDeep(this.value.model);
  }

  protected created() {
    this.$validator.extend(
      "percentage_conf",
      {
        getMessage: (field: string, params: string[]) => {
          return this.$t("percentage_confirm_error", {
            field,
            confirm: params[0]
          }).toString();
        },

        validate: (value: string, params: any[]) => {
          return !!(+value + +params[0] === 100);
        }
      },
      {
        hasTarget: true
      }
    );
  }
}
