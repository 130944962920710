export interface StrainMetrcDetails {
  cbd_level: number;
  indica_percentage: number | null;
  testing_status: string | null;
  thc_level: number;
  sativa_percentage: number | null;
  biotrack_id?: number | null;
  genetics?: string | null;
  name?: string | null;
}

export const strainMetrcDetailsDefault: StrainMetrcDetails = {
  cbd_level: 0,
  indica_percentage: null,
  testing_status: "None",
  thc_level: 0,
  sativa_percentage: null,
  biotrack_id: null,
  genetics: null
};
