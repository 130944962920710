import StrainNoteModalComponent from "@/components/inventory/strain/strainForm/strainNoteModal/StrainNoteModal.component";
import { Strain, StrainNote } from "@/interfaces/strain";
import { EventBus } from "@/internal";
import { StrainNotesTableMetadata } from "@/metadata/strain";
import { strainActionService } from "@/services/strain.action.service";
import { TableAction, TableComponent, TableHeader } from "helix-vue-components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FullNoteTableComponent from "../fullNoteTable/fullNoteTable.component";
import Template from "./StrainNotesTable.template.vue";

@Component({
  mixins: [Template],
  components: {
    FullNoteTableComponent,
    TableComponent,
    StrainNoteModalComponent
  }
})
export default class StrainNotesTableComponent extends Vue {
  public modalData = { title: "", width: "500px", height: "480px" };
  public headers: TableHeader[] = StrainNotesTableMetadata;
  @Prop({ default: () => [] }) public notes!: StrainNote[];
  @Prop({ default: null }) public loading!: boolean;
  @Prop({ default: null }) public strain!: Strain;
  public hasModifyPermission = true;
  public rowActions: TableAction[] = [];
  public notesFormated: StrainNote[] = [];
  public notesToTemplate: StrainNote[] = [];

  public mounted() {
    if (this.$route.name === "view-strain") {
      this.hasModifyPermission = false;
    }
    this.rowActions = strainActionService.getNotesRowActions(
      this.hasModifyPermission,
      this.editModal
    );
    EventBus.$on("addNote", this.addNote);
    EventBus.$on("updateNote", this.updateNote);
    EventBus.$on("removeNote", this.removeNote);
  }

  @Watch("notes")
  public formated() {
    this.notesFormated = (this.notes && [...this.notes]) || [];
  }

  public async newNote() {
    try {
      await this.$modals.load(StrainNoteModalComponent, {
        size: "fit",
        positionY: "top"
      });
    } catch (e) {
      // avoid uncaught rejection.
    }
  }

  public async editModal(item: any, position: number) {
    try {
      await this.$modals.load(
        StrainNoteModalComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          item,
          position
        }
      );
    } catch (e) {
      // avoid uncaught rejection.
    }
  }

  public addNote(note: StrainNote) {
    this.notesFormated.push(note);
    this.udpateNotes();
  }

  public updateNote(note: StrainNote) {
    this.notesFormated = this.notesFormated.map((noteItem, index) => {
      return note.position === index ? note : noteItem;
    });
    this.udpateNotes();
  }

  public removeNote(position: number) {
    this.notesFormated = this.notesFormated.map((note, index) => {
      return index === position ? { ...note, _destroy: true } : note;
    });
    this.udpateNotes();
  }

  public udpateNotes() {
    EventBus.$emit("updateNotes", this.notesFormated);
  }
}
