import StrainMetrcsDetailsComponent from "@/components/inventory/strain/strainForm/strainMetrcDetails/StrainMetrcDetails.component";
import StrainNotesTableComponent from "@/components/inventory/strain/strainForm/strainNotesTable/StrainNotesTable.component";
import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import SelectImageComponent from "@/components/sharedComponents/selectImage/SelectImage.component";
import { PusherNotification } from "@/interfaces/notification";
import { Strain, StrainNote } from "@/interfaces/strain";
import {
  StrainMetrcDetails,
  strainMetrcDetailsDefault
} from "@/interfaces/strainMetrc";
import { StrainType } from "@/interfaces/strainTypes";
import { EventBus, pusherEvents } from "@/internal";
import { metrcEnabled } from "@/router.utils";
import { strainService } from "@/services/strain.service";
import { strainTypesService } from "@/services/strainType.service";
import { Callback, PageNavAction } from "@/types/types";
import { BooleanCheck, CallbackPromise } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./StrainForm.template.vue";
const namespace: string = "StrainModule";

@Component({
  mixins: [Template],
  components: {
    StrainNotesTableComponent,
    SelectImageComponent,
    StrainMetrcsDetailsComponent
  },
  inject: ["$changes"]
})
export default class StrainFormComponent extends Vue {
  public strain: Strain = {
    name: "",
    strain_type_id: null,
    notes: [],
    rank: null
  };
  public isValid = false;
  public isSaving = false;
  public loading: boolean = false;
  public file!: File;
  public defaultImage: string | null = "";
  public hasMetrc = metrcEnabled();
  public existsOnMetrc = false;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  public metrcModel: {
    valid: boolean;
    metrcHasChanged: boolean;
    model: StrainMetrcDetails;
  } = {
    valid: false,
    model: { ...strainMetrcDetailsDefault },
    metrcHasChanged: false
  };
  public strainOriginalName: string = "";
  public hasModifyPermission: boolean = true;
  public strainTypesList: StrainType[] = [];
  public tab: string = "strain-notes";
  public isLoadingTypes = false;
  public percentages: { [key: number]: any } = {
    1: {
      id: 1,
      name: "Hybrid (50/50)",
      indica_percentage: 50,
      sativa_percentage: 50
    },

    2: {
      id: 2,
      name: "Hybrid (Indica Dominant)",
      indica_percentage: 75,
      sativa_percentage: 25
    },

    3: {
      id: 3,
      name: "Hybrid (Sativa Dominant)",
      indica_percentage: 25,
      sativa_percentage: 75
    },

    4: {
      id: 4,
      name: "Indica",
      indica_percentage: 100,
      sativa_percentage: 0
    },
    5: {
      id: 5,
      name: "Sativa",
      indica_percentage: 0,
      sativa_percentage: 100
    }
  };

  @Action("addStrain", { namespace })
  public addStrainAction!: CallbackPromise<Strain | null>;
  @Action("updateStrain", { namespace })
  public updateStrainAction!: CallbackPromise<Strain | null>;
  @Action("errorRedirect", { namespace: "RouterModule" })
  public errorRedirect!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("addStrainMetrcDetails", { namespace: "PageNavModule" })
  public addStrainMetrcDetailsAction!: PageNavAction;

  public async loadStrain() {
    if (this.$route.params.id) {
      try {
        this.loading = true;
        const strain: Strain = await strainService.getStrain(
          this.$route.params.id
        );
        this.loading = false;
        if (strain) {
          this.strain = strain;
          this.strainOriginalName = strain.name;
          if (this.strain.avatar && this.strain.avatar!.avatar_original_url) {
            this.defaultImage = this.strain.avatar!.avatar_original_url;
          }
        }
      } catch (error) {
        this.errorRedirect({ location: { name: "strains" }, error });
      }
    }
  }

  public updateNotes(data: StrainNote[]) {
    this.strain.notes = [...data];
  }

  public fileSelected(file: File) {
    this.file = file;
  }

  public async saveStrain() {
    this.isValid = await this.$validator.validateAll();
    this.isSaving = true;

    if (this.hasMetrc) {
      await (this.$refs["metrc-form"] as StrainMetrcsDetailsComponent).onChange(
        true
      );
    }

    if (this.hasMetrc && !this.metrcModel.valid) {
      this.tab = "strain-metrc";
      this.isSaving = false;
      return;
    }

    if (this.isValid) {
      const rank = this.strain.rank ? Number(this.strain.rank) : null;
      const strainData = { ...this.strain, rank };
      const strainResult: any | null = this.strain.id
        ? await this.updateStrainAction({
            strain: strainData,
            file: this.file
          })
        : await this.addStrainAction({
            strain: strainData,
            file: this.file
          });
      if (
        strainResult !== null &&
        this.hasMetrc &&
        (this.metrcModel.metrcHasChanged ||
          strainData.name !== this.strainOriginalName)
      ) {
        this.$modals
          .load(LoadingWindowComponent, {
            closable: false,
            size: "fit",
            positionY: "center",
            positionX: "center",
            backdrop: true
          })
          .catch(() => {
            // nothing to do
          });
        const metrcResponse = await strainService.setStrainMetrcDetails(
          strainData.id || strainResult.id,
          strainData.name,
          this.metrcModel.model as StrainMetrcDetails,
          this.existsOnMetrc
        );
        if (metrcResponse.errors) {
          EventBus.$emit("mtrcLoadingEvent", {
            show: true,
            errorList: metrcResponse.errors || []
          });
          return;
        } else {
          EventBus.$emit("mtrcLoadingEvent", {
            show: false
          });
        }
      }
     if(strainResult && strainResult.code=== 200){
      EventBus.$emit("notify", {
        text: "strain_saved",
        color: "success"
      });
     }
      this.back();
    }
    this.isSaving = false;
  }

  public back() {
    this.$router.back();
  }

  public async typeChanged() {
    this.strain.strain_type_id =
      (this.strain.strain_type && this.strain.strain_type.id) || null;
    if (this.strain.strain_type) {
      const typeSelected = this.percentages[this.strain.strain_type_id!];
      this.metrcModel.model.indica_percentage = typeSelected.indica_percentage;
      this.metrcModel.model.sativa_percentage = typeSelected.sativa_percentage;
    } else {
      this.metrcModel.model.indica_percentage = null;
      this.metrcModel.model.sativa_percentage = null;
    }
    this.metrcModel.model.genetics = null;
    await (this.$refs["metrc-form"] as StrainMetrcsDetailsComponent).onChange(
      false
    );
  }

  protected async loadMetrcData() {
    this.loading = true;
    const strainData = await strainService.getStrainMetrcDetails(
      +this.$route.params.id
    );
    if (strainData) {
      this.metrcModel.model = strainData;
      this.existsOnMetrc = true;
    }
    this.loading = false;
  }

  protected created() {
    this.$validator.extend("uniqueName", {
      getMessage: () => {
        return this.$t("name_already_exists_on_Metrc");
      },
      validate: async (value: string) => {
        const shouldValidate =
          this.hasMetrc &&
          (this.strain.name !== this.strainOriginalName || !this.strain.id);

        if (shouldValidate) {
          return !(await strainService.getStrainNameMetrcAvailability(
            this.strain.name
          ));
        } else {
          return true;
        }
      }
    });
  }

  protected async mounted() {
    if (this.$route.name === "view-strain") {
      this.hasModifyPermission = false;
    }
    this.setPageNav({
      title: "strain.strains",
      isLoading: () => this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            action: this.saveStrain,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            id: "btn_cancel",
            action: this.back,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    await this.loadStrain();
    if (this.hasMetrc) {
      await this.loadMetrcData();
    }
    this.isLoadingTypes = true;
    this.strainTypesList = await strainTypesService.get(null);
    this.isLoadingTypes = false;
    EventBus.$on("updateNotes", this.updateNotes);
    this.$changes.watch(
      pusherEvents.strainTouched,
      this.loadStrain,
      (data: PusherNotification) => {
        if (this.strain && this.strain.id) {
          return !!data.message.find(n => n.item_id === this.strain.id);
        }
        return false;
      }
    );
  }
}
