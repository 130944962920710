import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const StrainTableMetadata: TableHeader[] = [
  {
    label: i18n.t("name").toString(),
    value: "name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("strain.strain_type").toString(),
    value: "strain_type.name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("strain.strain_rank").toString(),
    value: "rank",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];

export const StrainNotesTableMetadata = [
  {
    label: i18n.t("strain.topic").toString(),
    value: "name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    label: i18n.t("date").toString(),
    value: "start_date",
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];
