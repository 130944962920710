import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./fullNoteTable.template.vue";

@Component({
  mixins: [Template]
})
export default class FullNoteTableComponent extends Vue {
  @Prop({ default: () => [] })
  public list!: any;
}
