import { noteDefault, StrainNote } from "@/interfaces/strain";
import { EventBus } from "@/internal";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./StrainNoteModal.template.vue";

@Component({
  mixins: [Template]
})
export default class StrainNoteModalComponent extends Vue {
  public note!: StrainNote;
  public isValid = false;
  @Prop({ default: null })
  public item!: StrainNote;
  @Prop({ default: null })
  public position!: number;

  constructor() {
    super();
    this.item
      ? (this.note = { ...this.item })
      : (this.note = { ...noteDefault });
  }

  public closeModal() {
    this.$emit("reject");
  }

  public notaAction() {
    const note = {
      ...this.note,
      start_date: fnsFormatDate(new Date(), FNS_DATE_FORMATS.BARS_DEFAULT)
    };
    if (this.isValid) {
      EventBus.$emit("addNote", note);
      this.closeModal();
    }
  }

  public updateNote() {
    const note = {
      ...this.note,
      position: this.position
    };
    if (this.isValid) {
      EventBus.$emit("updateNote", note);
      this.closeModal();
    }
  }
}
