import { policyList } from "@/enums/permissions";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  ActionSubheader,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";
import { strainService } from "./strain.service";
class StrainActionService {
  public getGeneralActions(
    csvCallback: () => void,
    filtersCallback: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-file-csv",
        action: csvCallback
      },
      {
        icon: "fal fa-plus",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: "add-strain"
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createStrains
          )
      },
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent",
          action: strainService.searchEvent()
        }
      },
      {
        icon: "fal fa-filter",
        action: filtersCallback,
        id: "btn_filter_prod"
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: store.getters["PermissionsModule/hasPermission"](
          policyList.modifyStrains
        )
          ? "fal fa-pen"
          : "fal fa-eye",
        id: "btn_edit_strain",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: store.getters["PermissionsModule/hasPermission"](
              policyList.modifyStrains
            )
              ? "edit-strain"
              : "view-strain",
            params: {
              id: arg[0].id,
              currentModel: arg[0]
            }
          });
        }
      },
      {
        icon: "",
        switch: "is_active",
        id: "btn_delete_strain",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableStrains
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("strain.strain_toggle_confirm").toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_delete_yes",
          modalSuccessAction: ({ item, unselectModal, modelValue }) => {
            strainService
              .toggleStrain(item, modelValue)
              .then(() => unselectModal())
              .finally(() => store.dispatch("StrainModule/loadStrains"));
          },
          modalCancelText: "No",
          modalIdCancel: "btn_delete_no"
        }
      }
    ];
  }

  public getNotesRowActions(
    modifyPermission: boolean,
    edit: (item: any, position: number) => void
  ) {
    return [
      {
        icon: "fal fa-pen",
        action: (item: any, position: number) => edit(item, position),
        visibleCondition: () => modifyPermission
      },
      {
        icon: "fal fa-trash-alt",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("strain.strain_note_delete_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            EventBus.$emit("removeNote", arg.position);
            arg.unselectModal();
          },
          modalCancelText: "No"
        },
        visibleCondition: () => modifyPermission
      }
    ];
  }

  public getMultiActions(): TableAction[] {
    return [
      {
        icon: "fal fa-eye",
        action(args: any) {
          store.dispatch("StrainModule/enableMultiple", args);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableStrains
          )
      },
      {
        icon: "fal fa-eye-slash",
        action(args: any) {
          store.dispatch("StrainModule/disableMultiple", args);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableStrains
          )
      }
    ];
  }
}

export const strainActionService = new StrainActionService();
