import { StrainType } from "@/interfaces/strainTypes";
import HttpService from "@/services/http.service";

class StrainTypesService extends HttpService {
  protected uri: string = "catalog/strain_types";

  public async get(query: object | null): Promise<StrainType[]> {
    const response = await super.get(query || this.query);
    return response.data.data;
  }
}

export const strainTypesService: StrainTypesService = new StrainTypesService();
